import {
  IMqttServiceOptions
} from 'ngx-mqtt';
import {environment} from '../../../environments/environment';

export function mqttOptions(data: IMqttServiceOptions = {}): IMqttServiceOptions {
  const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: environment.mqttUrl,
    port: environment.mqttPort,
    path: '/',
    username: sessionStorage.getItem('token'),
    password: 'gib-gibbery',
    clientId: window.location.href, // default,
    protocol: environment.production ? 'wss' : 'ws'
  };

  return Object.assign({}, MQTT_SERVICE_OPTIONS, data);
}
