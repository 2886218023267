export const environment = {
    production: true,
    apiUrl: 'https://api.forming.lenticul.net',
    mqttUrl: 'mqtt.forming.lenticul.net',
    mqttPort: 443,
    mode: 'default',
    language: 'en',
    aot: true,
    outputHashing: "all",
    isLenticul: true,
    origin: 'Forming Lenticul'
};